// @flow

import React from "react";
import GalleryThumbnail from "./GalleryThumbnail";
import {Controlled as ControlledZoom} from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import "./Gallery.scss";
import {Trans} from "react-i18next";

export type SlImage = {
    url: string,
    name: string,
    attachmentUrl: string,
    previewImage: ?SlImage,
};

type GalleryProps = {
    images: SlImage[],
    videos: {}[],
}

type GalleryState = {
    currentAttachment: {} | SlImage;
    isZoomed: boolean,
}

export class Gallery extends React.Component<GalleryProps, GalleryState> {
    constructor(props) {
        super(props);
        const firstImage = this.props.images ? this.props.images[0] : {};
        this.imageRef = React.createRef();
        this.state = {
            isZoomed: false,
            currentAttachment: firstImage,
            imageHeight: null,
            imageWidth: null,
        };
    }

    handleZoomChange = (shouldZoom: boolean) => {
        this.setState({
            isZoomed: shouldZoom,
            imageHeight: !this.state.imageHeight ? this.imageRef.current.clientHeight : this.state.imageHeight,
            imageWidth: !this.state.imageHeight ? this.imageRef.current.clientWidth : this.state.imageWidth
        });
    };

    selectAttachment(media) {
        this.setState({currentAttachment: media});
    }

    renderPreview() {
        if (this.props.images.length + this.props.videos.length > 1) {
            return <React.Fragment>
                {this.props.images.map((image: SlImage, index) => {
                    return <GalleryThumbnail
                        key={`IMG${index}`}
                        type={"Image"}
                        media={image}
                        selectAttachment={() => this.selectAttachment(image)}
                    />;
                })}
                {this.props.videos.map((video, index) => {
                    return <GalleryThumbnail
                        key={`VID${index}`}
                        type={"Video"}
                        media={video}
                        selectAttachment={() => this.selectAttachment(video)}
                    />;
                })}
            </React.Fragment>;
        }
    }

    renderMedia() {
        const imageStyle = {
            width: "auto",
            margin: "auto",
            maxHeight: this.state.imageHeight || "100%",
            maxWidth: this.state.imageWidth || "100%",
            display: "block",
            height: "auto"
        };

        if (this.state.currentAttachment.mimeType.startsWith("image/")) {
            return (
                <ControlledZoom
                    zoomMargin={16}
                    wrapElement="figure"
                    onZoomChange={(shouldZoom: boolean) => this.handleZoomChange(shouldZoom)}
                    isZoomed={this.state.isZoomed}
                >
                    <img
                        ref={this.imageRef}
                        src={this.state.currentAttachment.attachmentUrl}
                        style={imageStyle}
                        alt={this.state.currentAttachment.name}
                    />
                </ControlledZoom>
            );
        } else {
            return (
                <div className="galleryVideo">
                    <video
                        ref={(video) => {
                            if (video) {
                                video.load();
                            }
                        }}
                        controls
                        style={imageStyle}
                    >
                        <source
                            src={this.state.currentAttachment.attachmentUrl}
                            type="video/mp4"
                        />
                        <Trans i18nKey="gallery.error" />
                    </video>
                </div>
            );
        }
    }

    render() {
        return <div className="gallery" data-testid={"gallery"}>
            <div className="image">
                {this.renderMedia()}
            </div>
            <div className="previewImages">
                {this.renderPreview()}
            </div>
        </div>;
    }
};
